const getSubdomain = function () {
  const fullDomainName = window.location.hostname.toLowerCase()

  /* check for special cases, like localhost and route those
     accordinly using only the first part of the domain name */
  const subdomain = fullDomainName.split('.')[0]
  if (subdomain.indexOf('localhost') > -1 || subdomain.indexOf('127.0.0.1') > -1) {
    return false
  }
  return fullDomainName
}

module.exports = { getSubdomain:getSubdomain }
