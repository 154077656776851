import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import AnalyticsContextHOC from './AnalyticsContextHOC'

const api = require("./api")
const Subdomain = require("./subdomain")

class Accept extends Component {

  constructor(props){
    super(props)
    this.state = {
      rest: this.props.rest,
      referer: this.props.referer,
      campaignID: this.props.campaignID,
      email:'',
      code:null,
      error: false,
      success: false
    }
    Sentry.init({dsn: "https://563a72baf7464f98b91a20b7eb55ca81@sentry.io/1480818"})
  }

  componentWillMount(){
    var subdomain = Subdomain.getSubdomain()
    let payload = {
      coupon: this.props.coupon, 
      campaign_id: this.props.campaignID,
      referrer: this.props.referer,
      version: process.env.REACT_APP_VERSION
    }
    api.callApi(
      "coupon_info",
      (res)=>this.handleCouponInfo(res),
      (res)=>console.log('FAILURE LOADING COUPON INFO:\n', res),
      payload,
      null,
      null,
      subdomain
    )
  }

  componentDidMount() {
    this.props.analytics.send('KeycodeComponentLoad')
  }

  handleCouponInfo(info){
    if (info.action_name === 'flat_discount'){
      let text = '$' + info.action_value/100
      this.setState({couponText: text})
    }
    if (info.action_name === 'percent_discount'){
      let text = info.action_value*100 + '%'
      this.setState({couponText: text})
    }
  }

  handleSubmitEmail(){
    // eslint-disable-next-line
    this.props.analytics.send('SubmittedEmailAddress')
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(this.state.email).toLowerCase())){
      var subdomain = Subdomain.getSubdomain();
      let payload={
        email: this.state.email,
        restaurant_id: this.props.rest.rest_id,
        coupon_uuid: this.props.coupon,
        campaign_uuid: this.props.campaignID,
        source: 'cyp-coupon',
        referrer: this.props.referer,
        version: process.env.REACT_APP_VERSION
      }

      //SEND COUPON TO EMAIL ADDRESS
      if (!this.state.code) {
        api.callApi(
          "add_marketing_email",
          (res)=>this.handleKeycodeSuccess(res),
          (res)=>this.handleKeycodeFailure(res),
          payload,
          null,
          null,
          subdomain
        )
      } else {
        this.setState({error: 'Whoops! Only one code per person.'})
      }
    } else {
      this.setState({error: 'Unable to process request, please try again'})
    }
  }

  handleKeycodeSuccess(res){
    if (res.result === "OK"){
      console.log('keycode success')
      this.props.analytics.send('KeyCodeSuccess')
      this.setState({success:true, code: true})
    }
    else{
      this.handleKeycodeFailure(res)
    }
  }

  handleKeycodeFailure(res){
    this.props.analytics.send('KeyCodeErrorMessage')
    const errorMessage = 'Whoops, looks like there was an error: ' + res.result
    this.setState({error: errorMessage})
  }

  renderErrorSuccess(){
    if (this.state.error) {
      this.props.analytics.send('CouponErrorMessage', this.state.error)
      return(<div className='error'>{this.state.error}</div>)
    }
    else if (this.state.success){
      this.props.analytics.send('CouponSentMessage')
      return(<div className='code'>Coupon sent - check your inbox!</div>)
    }
  }

  render(){
    return (
      <div>
        <div className='header'>Dinner's on us.<br/>{this.state.couponText} off your next order.</div>
        <br/>
        {this.renderErrorSuccess()}
        <input
          value={this.state.email}
          onChange={(e)=>this.setState({email:e.target.value, error: false})}
          onFocus={(e)=>this.props.analytics.send('EmailFormInputFocus')}
          onBlur={(e)=>this.props.analytics.send('EmailFormInputBlur')}
          className='email' placeholder='enter your email address'
          type='email'
          />
        <br/>
        <button onClick={(e)=>this.handleSubmitEmail(e.target.value)} className='submit' style={{background:this.state.rest.hex_color_primary}}>SEND DISCOUNT CODE</button>
        <div className='fine-print'>
          Offer limited to one use per customer. Cannot be shared or combined with other offers. Only available for direct online orders, while supplies last. Terms are subject to change.
        </div>
      </div>
    );
  }
}

export default AnalyticsContextHOC(Accept);
