var callApi = function(endpoint_name, success, failure=function(){}, data={}, url_params={}, resource_id) {
  var endpoint = ''
  var method = 'post'

  if(endpoint_name === 'rest_basic_info'){
    endpoint = `/cyp/campaign/${resource_id}`
    method = 'get'
  }

  else if (endpoint_name === 'add_marketing_email'){
    endpoint = `/cyp/add-marketing-email`
    method = 'post'
  }

  else if (endpoint_name === "coupon_info"){
    endpoint = `/cyp/coupon/${data.coupon}`
    method = 'get'
  }

  else{
    alert(endpoint_name + ' is not a valid endpoint')
  }
  
  var url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}${endpoint}`
  let headers = {'Content-Type': 'application/json'}

  if(method === 'post'){
    fetch(url, {method:'post', headers: headers, body:JSON.stringify(data)})
      .then((jsonresponse) => jsonresponse.json())
      .then((response) => {
        success(response);
      })  
      .catch((err) => {
        console.log(err)
        failure(err)
      })
    }

  if (method === 'get') {
    fetch(url, {method: 'get'})
      .then((jsonresponse) => jsonresponse.json())
      .then((response) => {
        success(response)
      })  
      .catch((err) => {
        console.log(err)
        failure(err)
      })

  }
} 

module.exports = { callApi:callApi }
