import React, { Component } from 'react';
import './App.css';
import Accept from './Accept'
import Defer from './Defer'
import reactReferer from 'react-referer'
import * as Sentry from '@sentry/browser';
import AnalyticsContextHOC from './AnalyticsContextHOC'

const api = require("./api")
const Subdomain = require("./subdomain")

class App extends Component {

  constructor(props){
    Sentry.init({dsn: "https://563a72baf7464f98b91a20b7eb55ca81@sentry.io/1480818"})
    super(props)

    let referer = reactReferer.referer()
    if (props.referrerBypass === true) {
      referer = 'facebook'
    }
 
    var subdomain = Subdomain.getSubdomain()
    api.callApi(
      "rest_basic_info",
      this.setRestInfo.bind(this),
      console.log,
      null,
      null,
      subdomain
    )

    console.log('USER REFERED FROM:\n', referer)
    let referAccept = false
    if (referer.includes('facebook') || referer.includes('instagram')) {
      referAccept = true
    }

    this.state = {
      referAccept: referAccept,
      referer: referer, //'facebook' to force
      rest: null,
      couponID: null,
      campaignID: null
    }
  }

  setRestInfo(data){
    this.setState({
      rest: data,
      couponID: data.coupon_id,
      campaignID: data.campaign_id
    })
  }

  renderContent(){
    if (this.state.referAccept){
      return <Accept rest={this.state.rest} 
        campaignID={this.state.campaignID}
        referer={this.state.referer}
        coupon={this.state.couponID} />
    }
    return <Defer rest={this.state.rest}
      referer={this.state.referer}
      campaignID={this.state.campaignID} />
  }

  render(){
    if (this.state.rest) {
      return (
        <div className="App">
          <iframe className='rest-site-bg' title='rest-site' src={this.state.rest.website_url}/>
          <div className='modal'>
            <a href={this.state.rest.website_url}>
              <img src='/close.svg' className='close' alt='close'
                onClick={(e)=>this.props.analytics.send('CloseButtonPress')}
              />
            </a>
            {this.renderContent()}
          </div>
        </div>
      );
    } else {
      return(<div>loading...</div>)
    }
  }
}

export default AnalyticsContextHOC(App);
