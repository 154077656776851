import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import AnalyticsContextHOC from './AnalyticsContextHOC'

const api = require("./api")
const Subdomain = require("./subdomain")

class Defer extends Component {

  constructor(props){
    super(props)
    this.state = {
      rest: this.props.rest,
      email:'',
      campaignID: this.props.campaignID,
      referer: this.props.referer,
      error: false,
      success: false
    }
    Sentry.init({dsn: "https://563a72baf7464f98b91a20b7eb55ca81@sentry.io/1480818"})
  }

  componentDidMount() {
    this.props.analytics.send('EmailComponentLoaded')
  }

  handleSubmitEmail(){
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(this.state.email).toLowerCase())) {
      var subdomain = Subdomain.getSubdomain()
      const payload = {
        email: this.state.email,
        restaurant_id: this.props.rest.rest_id,
        campaign_uuid: this.props.campaignID,
        source: 'cyp',
        referrer: this.props.referer,
        version: process.env.REACT_APP_VERSION}
      api.callApi(
        "add_marketing_email",
        (res)=>this.handleEmailSuccess(res),
        (res)=>this.handleEmailFailure(res),
        payload,
        null,
        null,
        subdomain)
    } else {
      this.setState({error: 'Invalid Email Address'})
    }
  }

  handleEmailSuccess(res){
    this.props.analytics.send('EmailRegistrationSuccess')
    console.log('email success: ', res)
    this.setState({success:true})
  }

  handleEmailFailure(res) {
    this.props.analytics.send('EmailRegistrationError')
    this.setState({error: res})
  }

  renderErrorSuccess(){
    if (this.state.error) {
      return(<div className='error'>Looks like there was an error: {this.state.error}</div>)
    }
    else if (this.state.success) {
      return(<div className='success'>Email added successfully!</div>)
    }
  }

  render(){
    return (
      <div>
        <div className='header'>Be the first to find out about specials!<br/>Sign up below:</div>
        <br/>
        <input
          value={this.state.email}
          onChange={(e)=>this.setState({email:e.target.value, error:false})}
          onFocus={(e)=>this.props.analytics.send('EmailFormInputFocus')}
          onBlur={(e)=>this.props.analytics.send('EmailFormInputBlur')}
          className='email' placeholder='your email address...'
          type='email'
          />
        <br/>
        {this.renderErrorSuccess()}
        <button onClick={()=>this.handleSubmitEmail()}className='submit' style={{background:this.state.rest.hex_color_primary}}>JOIN NOW</button>
      </div>
    );
  }
}

export default AnalyticsContextHOC(Defer);
