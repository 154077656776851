import React, { Component } from 'react'
import ReactGA from 'react-ga'

export const AnalyticsContext = React.createContext()

class AnalyticsProvider extends Component {

  constructor(props){
    super(props)
    ReactGA.initialize(props.gaTrackingID)

    this.send = this.send.bind(this)
    this.pageview = this.pageview.bind(this)

    this.state = {
      subdomain: props.subdomain,
      send: (act, label) => this.send(act, label),
      pageview: (url) => this.pageview(url),
    }
  }

  send(action, label=null) {
    let payload = {category: this.state.subdomain, 
      action: action, label: label}
    ReactGA.event(payload)
  }

  pageview(url) {
    ReactGA.pageview(url)
  }

  render() {
    return  (
      <AnalyticsContext.Provider value={this.state}>
        {this.props.children}
      </AnalyticsContext.Provider>
    )
  }
}

export default AnalyticsProvider;
