import "react-app-polyfill/ie11";
import "url-search-params-polyfill";

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import queryString from 'query-string'
import App from './App'
import AnalyticsProvider from "./AnalyticsContext";

const Subdomain = require("./subdomain")

// polyfill for IE missing the includes method
// which is required for some stuff (mostly sentry)
// https://mzl.la/2MnpSqD
if (!String.prototype.includes) {
  // eslint-disable-next-line
  String.prototype.includes = function(search, start) {
    // eslint-disable-next-line
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

class Routes extends Component {

  render() {
    const parsed = queryString.parse(window.location.search)
    const secretParam = parsed.secret
    const expectedSecret = process.env.REACT_APP_FACEBOOK_FAKE_CODE
 
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_ID
    })
   
    return (
      <div>
        <Route exact 
          params={this.props.location}
          render={() => <App 
            referrerBypass={(secretParam === expectedSecret)} />}
        />
      </div>
    );
  }
}

ReactDOM.render(
  <BrowserRouter>
    <AnalyticsProvider
      gaTrackingID={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}
      version={process.env.REACT_APP_VERSION}
      subdomain={Subdomain.getSubdomain()}>
      <Routes/>
    </AnalyticsProvider>
  </BrowserRouter>, document.getElementById('root'))
