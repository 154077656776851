import React from 'react';
import {AnalyticsContext} from "./AnalyticsContext";

export default function AnalyticsContextHOC(Component){
  return React.forwardRef((props, ref) => (
      <AnalyticsContext.Consumer>
          {
              (context) => <Component ref={ref} analytics={context} {...props} /> 
          }
      </AnalyticsContext.Consumer>
    ))
}
